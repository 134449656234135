<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF INDUCTED</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation mt-6">
        <v-card-text>
          <v-col
            md="3"
            cols="12"
          ></v-col>
          <v-row>
            <v-col
              md="4"
              cols="12"
            >
              <v-select
                dense
                outlined
                v-model="induction_details"
                :items="induction_items"
                item-text="id"
                item-value="id"
                label="Inductions"
                required
                @change="selected_induction"
                :rules="rules.combobox_rule"
              ></v-select>

              <span class="headline" v-if="data.length>0">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              No.
            </th>
            <th class="text-uppercase">
              Name
            </th>
            <th class="text-center text-uppercase">
              SOA
            </th>
            <th class="text-center text-uppercase">
              Amount
            </th>
            <th class="text-center text-uppercase">
              Balance
            </th>
            <th class="text-center text-uppercase">
              Year Of
            </th>
            <th class="text-uppercase">
              Location
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td >
              {{ (data.map(function (x) {
              return x.id;
              }).indexOf(item.id)+1) }}
            </td>
            <td >
              {{ item.members.last_name+', '+item.members.first_name+' '+item.members.middle_name }}
            </td>
            <td class="text-center">
              <div>
                <v-icon
                  v-if="item.payments.length>0"
                  class="mr-2"
                  color="success"
                  @click="view_soa(item)"
                >
                  {{icons.mdiCash100}}
                </v-icon>
              </div>
            </td>
            <td class="text-center">
              {{ formatPrice(item.amount) }}
            </td>
            <td class="text-center">
              {{ formatPrice(get_balance(item)) }}
            </td>
            <td class="text-center">
              {{ item.year_of }}
            </td>
            <td>
              {{ item.location }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="view_dialog" max-width="50%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">VIEW SOA</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-3">
          <v-simple-table dense class="pt-2">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-center text-uppercase">
                  ID
                </th>
                <th class="text-center text-uppercase">
                  Date
                </th>
                <th class="text-center text-uppercase">
                  Amount
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in data_soa"
                :key="item.id"
              >
                <td class="text-center">
                  {{ item.id }}
                </td>
                <td class="text-center">
                  {{ item.deposits.date_of_deposit }}
                </td>
                <td class="text-center">
                  {{ formatPrice(item.amount) }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>

    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAlertOutline,
    mdiCloudUploadOutline,
    mdiAccountPlus,
    mdiCash,
    mdiPrinter,
    mdiDelete,
    mdiCash100,
    mdiAccountSearchOutline
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving: false,
      view_dialog: false,
      alert: false,
      alert_message: '',

      name_of_sponsor: '',
      search_items: [],
      data: [],
      data_soa: [],

      year_of: '',
      amount: '',
      location: '',
      induction_details: '',
      induction_items: [
        {
          id: '2nd Induction',
          year_of: 2024,
          amount: 15000,
          location: 'BORACAY ISLAND, MALAY, AKLAN'
        },
        {
          id: '1st Induction',
          year_of: 2023,
          amount: 1000,
          location: 'BIG 8 HOTEL, DIGOS CITY, DAVAO DEL SUR'
        },],
    }
  }

  export default {
    components: {
      snackBarDialog,
    },
    props: {
      value: Object,
      is_edit: Boolean,
    },
    setup() {
      return {
        show: false,

        icons: {
          mdiDelete,
          mdiAccountSearchOutline,
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountPlus,
          mdiCash,
          mdiPrinter,
          mdiCash100,
        },
      }
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id', 'district_id', 'district_name', 'user_id', 'month_start', 'month_end']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('system_data', ['company_logo']),
    },
    methods: {
      ...mapActions('induction_histories', ['create_induction_history', 'list_of_inductions','delete_induction_history']),
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_information', ['search_all_members']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      get_balance(item) {
        var payment = 0;
        item.payments.forEach(function (item) {
          payment += parseFloat(item.amount)
        })
        return (parseFloat(item.amount) - parseFloat(payment))
      },
      searching_search_items(value) {
        this.search_all_members({
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      delete_data(item) {
        this.saving = true
        this.delete_induction_history({
          id: item.id
        })
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.saving = false
            this.selected_induction()
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            console.log(error)
            this.saving = false
          })
      },
      selected_induction() {
        var index = this.induction_items.map(function (x) {
          return x.id;
        }).indexOf(this.induction_details)
        if (index != -1) {
          this.list_of_inductions({
            details: this.induction_details
          })
            .then(response => {
              this.data = response.data
            })
          this.year_of = this.induction_items[index].year_of + ''
          this.amount = this.induction_items[index].amount + ''
          this.location = this.induction_items[index].location
        }
      },
      async save_member() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var proceed = true;
          if (this.name_of_sponsor != null) {
            if (this.name_of_sponsor.id === undefined) {
              this.alert = true
              this.alert_message = 'Please Search Referer First'
              this.saving = false
              proceed = false
            }
          }
          if (proceed) {
            const data = new FormData()
            data.append('details', this.induction_details);
            data.append('member_id', this.name_of_sponsor.id);
            data.append('amount', this.amount);
            data.append('year_of', this.year_of);
            data.append('location', this.location.toUpperCase());
            this.create_induction_history(data)
              .then(response => {
                var color = 'success'
                if (response.status === 201) {
                  color = 'error'
                }
                this.change_snackbar({
                  show: true,
                  color: color,
                  text: response.data,
                })
                this.saving = false
                this.selected_induction()
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                console.log(error)
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      view_soa(items) {
        this.view_dialog=true
        this.data_soa = items.payments
      },
      print_data() {
        var imgData = this.company_logo
        var array = []
        array.push(
          [
            {text: 'No.', alignment: 'center', style: 'label'},
            {text: 'NAME', alignment: 'center', style: 'label'},
            {text: 'AMOUNT', alignment: 'center', style: 'label'},
            {text: 'BALANCE', alignment: 'center', style: 'label'},
            {text: 'YEAR OF', alignment: 'center', style: 'label'},
            {text: 'LOCATION', alignment: 'center', style: 'label'},
          ]
        )

        if (this.data.length > 0) {
          this.data.forEach(function (item, index) {
            var payment = 0;
            item.payments.forEach(function (item) {
              payment += parseFloat(item.amount)
            })

            array.push(
              [
                {
                  text: index+1,
                  alignment: 'left'
                },
                {text: item.members.last_name+', '+item.members.first_name+' '+item.members.middle_name , alignment: 'left'},
                {text: (item.amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), alignment: 'left'},
                {text:  ((parseFloat(item.amount) - parseFloat(payment)) / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), alignment: 'left'},
                {
                  text: item.year_of,
                  alignment: 'left'
                },
                {text: item.location, alignment: 'center'},
              ]
            )
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE COMMUNITY CARE FOUNDATION',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C REG. No. NA CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: this.induction_details, style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [50,150, 50, 50, 30, 150],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
